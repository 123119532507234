<template>
	<div>
		<div class="border rounded p-2 mb-4">
			<div class="row">
				<div class="col">
					<!-- <div
						:class="!showFilters ? 'btn-primary' : 'btn-secondary'"
						class="btn bg-primary-alt"
						@click="showFilters = !showFilters">
						<i
							class="fa fa-filter"
							aria-hidden="true" />
					</div> -->
				</div>
			</div>
			<!-- <data-filter
				:display="showFilters"
				get-by="name"
				@submit="getDataFiltered"
				@clear="$router.replace({query: null})">
				<template slot="form">
					<div class="row">
						<div class="col-4">
							<div
								class="form-group">
								<label
									for="dateRange"
									class="label">{{ translate('date_range') }}</label>
								<select
									id="dateRange"
									v-model="dateRange"
									name="dateRange"
									class="form-control">
									<option
										:value=" '' ">{{ translate('all') }}</option>
									<option
										v-for="dateRange in dateRanges"
										:value="dateRange"
										:key="dateRange">{{ translate(dateRange) }}</option>
								</select>
							</div>
						</div>
						<div
							v-if="dateRange === dateRanges.custom"
							class="col">
							<label
								for="startDate"
								class="label">{{ translate('start_date') }}</label>
							<v-date-picker
								id="startDate"
								v-model="startDate"
								:input-props="{ class: 'form-control' }"
								:formats="calendarConfig"
								name="startDate"
								show-caps />
						</div>
						<div
							v-if="dateRange === dateRanges.custom"
							class="col">
							<label
								for="endDate"
								class="label">{{ translate('end_date') }}</label>
							<v-date-picker
								id="endate"
								v-model="endDate"
								:input-props="{ class: 'form-control' }"
								:formats="calendarConfig"
								name="endDate"
								show-caps />
						</div>
					</div>
				</template>
			</data-filter> -->
			<div class="row mt-3 mb-2">
				<div class="col">
					<period-totals-info :options="options" />
				</div>
			</div>
		</div>
		<div
			v-if="showRegionalSalesMap"
			class="row mb-4 dashboard-map-row">
			<div
				v-if="showRegionalSalesMap"
				:class="['xs','sm'].includes(windowWidth) ? 'mb-4 h-50' : 'h-100'"
				class="col-md-6 dashboard-map-col">
				<regional-sales-map :show-legend="!['xs','sm'].includes(windowWidth)" />
			</div>
			<div
				v-if="showAllCountries"
				:class="['xs','sm'].includes(windowWidth) ? 'mb-4 h-50' : 'h-100'"
				class="col-md-6 dashboard-map-col">
				<country-sales-map
					:show-legend="!['xs','sm'].includes(windowWidth)" />
			</div>
		</div>
		<div class="row mb-4">
			<div class="col">
				<distributor-leaderboard />
			</div>
		</div>
		<div class="row mb-4">
			<div class="col">
				<top-users :count="topCount" />
			</div>
		</div>
		<div class="row mb-4">
			<div class="col">
				<income-totals-graph />
			</div>
		</div>
		<div
			v-if="showAllCountries"
			class="row mb-4">
			<div
				:class="{'mb-4' : ['xs','sm'].includes(windowWidth)}"
				class="col-md-5">
				<organization-ranks class="h-100" />
			</div>
			<div class="col-md-7">
				<organization-packages class="h-100" />
			</div>
		</div>
		<div class="row mb-4">
			<div class="col-12">
				<new-registrations-graph />
			</div>
		</div>
		<div class="row mb-4">
			<div class="col">
				<user-totals-info />
			</div>
		</div>
	</div>
</template>
<script>
import {
	DATE_RANGES as dateRanges,
	V_CALENDAR_CONFIG as calendarConfig,
} from '@/settings/Dates';
import {
	ADMIN_DASHBOARD_TOP_USERS_COUNT as topCount,
	SALES_BY_STATE_VALID_CORPORATE_COUNTRY as validCorporatesState,
} from '@/settings/Dashboard';
import { Grids } from '@/translations';
import WindowSizes from '@/mixins/WindowSizes';
import FiltersParams from '@/mixins/FiltersParams';
// import DataFilter from '@/components/DataFilter';
import PeriodTotalsInfo from './components/PeriodTotalsInfo';
import UserTotalsInfo from './components/UserTotalsInfo';
import IncomeTotalsGraph from './components/IncomeTotalsGraph';
import NewRegistrationsGraph from './components/NewRegistrationsGraph';
import OrganizationPackages from './components/OrganizationPackages';
import OrganizationRanks from './components/OrganizationRanks';
import CountrySalesMap from './components/CountrySalesMap';
import RegionalSalesMap from './components/RegionalSalesMap';
import TopUsers from './components/TopUsers';
import DistributorLeaderboard from '@/views/Dashboard/components/DistributorLeaderboard';

export default {
	name: 'AdminDashboard',
	messages: [Grids],
	components: {
		TopUsers,
		PeriodTotalsInfo,
		UserTotalsInfo,
		IncomeTotalsGraph,
		NewRegistrationsGraph,
		OrganizationPackages,
		OrganizationRanks,
		CountrySalesMap,
		RegionalSalesMap,
		DistributorLeaderboard,
		// DataFilter,
	},
	mixins: [FiltersParams, WindowSizes],
	data() {
		return {
			calendarConfig,
			dateRanges,
			options: {},
			rangeStart: '',
			rangeEnd: '',
			topCount,
			validCorporatesState,
		};
	},
	computed: {
		datePeriod() {
			const start = this.rangeStart;
			const end = this.rangeEnd;
			if (start !== '' && end !== '') {
				return this.translate('date_period', { start, end });
			}

			if (start !== '') {
				return this.translate('date_period_from', { start });
			}

			if (end !== '') {
				return this.translate('date_period_until', { end });
			}
			return '';
		},
		showAllCountries() {
			return this.$user.details().country === undefined;
		},
		showRegionalSalesMap() {
			return this.showAllCountries || this.validCorporatesState.includes(this.$user.details().country.iso_code_2);
		},
	},
	methods: {
		getDataFiltered() {
			const { query } = this.$route;
			this.options = { ...query, ...this.filters };
		},
	},
};
</script>
<style>
@media only screen and (max-width: 768px) {
	.dashboard-map-row {
		height: 936px;
		max-height: 936px;
	}
	.dashboard-map-col {
		height: 450px;
		max-height: 450px;
		margin-bottom: 24px;
	}
}
@media only screen and (min-width: 768px) {
	.dashboard-map-row {
		height: 450px;
		max-height: 450px;
	}
}
</style>
